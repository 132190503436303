// extracted by mini-css-extract-plugin
export var buttonContainer = "UserProjectProposals_Tile__buttonContainer__EujEQ";
export var cancelled = "UserProjectProposals_Tile__cancelled__btHrt";
export var changing = "UserProjectProposals_Tile__changing__irN66";
export var column = "UserProjectProposals_Tile__column__foBrt";
export var container = "UserProjectProposals_Tile__container__Y1lT9";
export var dateLabel = "UserProjectProposals_Tile__dateLabel__fSaeD";
export var dateValue = "UserProjectProposals_Tile__dateValue__rtz1z";
export var deleteProposal = "UserProjectProposals_Tile__deleteProposal__TYKkU";
export var downloadButton = "UserProjectProposals_Tile__downloadButton__L2y_l";
export var dropdown = "UserProjectProposals_Tile__dropdown__RQYOH";
export var dropdownBody = "UserProjectProposals_Tile__dropdownBody__gGlNO";
export var expireDate = "UserProjectProposals_Tile__expireDate__Mi_pW";
export var expired = "UserProjectProposals_Tile__expired__LzjuF";
export var finalized = "UserProjectProposals_Tile__finalized__wwu6h";
export var flex = "UserProjectProposals_Tile__flex__MgYPD";
export var flexColumn = "UserProjectProposals_Tile__flexColumn__mWBQY";
export var gap1 = "UserProjectProposals_Tile__gap1__lXgjx";
export var gap2 = "UserProjectProposals_Tile__gap2__k6xXU";
export var gap3 = "UserProjectProposals_Tile__gap3__Y8k_Q";
export var gap4 = "UserProjectProposals_Tile__gap4__iZ4OJ";
export var gap5 = "UserProjectProposals_Tile__gap5__TGlvW";
export var inProgress = "UserProjectProposals_Tile__inProgress__OzO8d";
export var label = "UserProjectProposals_Tile__label__aO2Bw";
export var labelValuePair = "UserProjectProposals_Tile__labelValuePair__nCgzs";
export var left = "UserProjectProposals_Tile__left__e_c7L";
export var logo = "UserProjectProposals_Tile__logo___fdq_";
export var logoContainer = "UserProjectProposals_Tile__logoContainer__KqroZ";
export var newProjectMenu = "UserProjectProposals_Tile__newProjectMenu__KUGfQ";
export var newProjectMenuItem = "UserProjectProposals_Tile__newProjectMenuItem__zwTna";
export var newProjectMenuItemControl = "UserProjectProposals_Tile__newProjectMenuItemControl__m9fAD";
export var nobid = "UserProjectProposals_Tile__nobid___Ifju";
export var proposalDates = "UserProjectProposals_Tile__proposalDates__WbyPO";
export var proposalModalMessageButtons = "UserProjectProposals_Tile__proposalModalMessageButtons__Kg1cR";
export var proposalModalMessageContainer = "UserProjectProposals_Tile__proposalModalMessageContainer__RDikD";
export var proposalModalMessageOverlay = "UserProjectProposals_Tile__proposalModalMessageOverlay__VnKuT";
export var proposalModalMessageText = "UserProjectProposals_Tile__proposalModalMessageText__XVqps";
export var proposalSvg = "UserProjectProposals_Tile__proposalSvg__wIlxr";
export var providerDesc = "UserProjectProposals_Tile__providerDesc__iqJve";
export var providerName = "UserProjectProposals_Tile__providerName__E0rtv";
export var right = "UserProjectProposals_Tile__right__ngRhz";
export var row = "UserProjectProposals_Tile__row__wRE_N";
export var soonBadge = "UserProjectProposals_Tile__soonBadge__GIR8P";
export var statusDropdown = "UserProjectProposals_Tile__statusDropdown__PNmgV";
export var statusIcon = "UserProjectProposals_Tile__statusIcon__y2qbh";
export var tourButton = "UserProjectProposals_Tile__tourButton__fAZ9x";
export var tourButtonPreparing = "UserProjectProposals_Tile__tourButtonPreparing__aUQSK";
export var tourSentButton = "UserProjectProposals_Tile__tourSentButton__kHGRf";
export var tourSentButtonPreparing = "UserProjectProposals_Tile__tourSentButtonPreparing__A911U";